import store from "@/store";
import Auth from "@aws-amplify/auth";

export const authenticationGuard = async (to, from, next) => {
  try {
    // NOTE try to get authenticate user
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      console.log(user, to, from);
      const session = await Auth.currentSession();
      const groups = session.getIdToken().payload["cognito:groups"];
      console.log({ groups });
      if (groups === undefined || groups === null) {
        return next("/wait-approve");
      }

      if (!groups.includes("Sale")) {
        return next("/wait-approve");
      }
    }
    // NOTE go to next page
    return next();
  } catch (error) {
    // NOTE redirect to sign in page
    return next({ name: "Auth" });
  }
};

export const adminGuard = async (to, from, next) => {
  try {
    // NOTE try to get authenticate user
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      console.log(user, to, from);
      const session = await Auth.currentSession();
      const groups = session.getIdToken().payload["cognito:groups"];
      console.log({ groups });
      if (!groups.includes("Admin")) {
        return next("/wait-approve");
      }
    }
    // NOTE go to next page
    return next();
  } catch (error) {
    // NOTE redirect to sign in page
    return next("/auth");
  }
};

export const authenticationGuardWithRedirectBack = async (to, from, next) => {
  try {
    // NOTE try to get authenticate user
    await store.dispatch("authentication/CheckAuthenticatedUser");

    // NOTE go to next page
    return next();
  } catch (error) {
    // NOTE redirect to sign in page
    return next(`/signin?redirect=${window.location.pathname}`);
  }
};
