// Production
export const ENDPOINT =
  "https://ccia7fz3sl.execute-api.ap-southeast-1.amazonaws.com";

// Development
// export const ENDPOINT =
//   "https://043mxmao5j.execute-api.ap-southeast-1.amazonaws.com/dev";

export const CUSTOMER_ENDPOINT =
  "https://hkf9vqpqe4.execute-api.ap-southeast-1.amazonaws.com/Prod";
// export const ORDER_HISTORY_ENDPOINT =
//   "http://3.1.190.110:8080/https://villa.kitchen/api/order/getUser";

export const TAG_ENDPOINT =
  "https://ccia7fz3sl.execute-api.ap-southeast-1.amazonaws.com/production";

export const COMPLETE_MESSAGE_ENDPOINT = "https://raw.githubusercontent.com/AvilonRobotics/villa-chat-content/main/messages.json"


export const DASHBOARD_ENDPOINT = "https://sxjrqmty61.execute-api.ap-southeast-1.amazonaws.com"