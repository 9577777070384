import axios from "axios";

const API_ENDPOINT =
  "https://043mxmao5j.execute-api.ap-southeast-1.amazonaws.com/dev";

const headers = {
  Authorization:
    "eyJraWQiOiJaR1NUbTJOeThKTVNBcFo5cGFVdXE2Ykk4Tml1dmxoT2Fjd3lPeU9XOFdNPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJkYzJjYmUyYS0xNDM5LTQ1MTQtODU0ZS0yZjJlZGQxODM0YmEiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGhlYXN0LTEuYW1hem9uYXdzLmNvbVwvYXAtc291dGhlYXN0LTFfSFlNb3lrdVNaIiwiY2xpZW50X2lkIjoiamMwaDIxdThwczZrcG9sNWdoZnQ1aDc2OSIsIm9yaWdpbl9qdGkiOiJkZTQyMjQxMS1hOTU1LTQ4MDAtOGQ2Ni05Y2Q0MjM3YTIyYWQiLCJldmVudF9pZCI6IjIyNmI2OWIyLTVhMDMtNDZmMi1iYWRhLTBiODY2N2JkMzYwNSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NTQyNzE4MzcsImV4cCI6MTY1NDI3NTQzNywiaWF0IjoxNjU0MjcxODM3LCJqdGkiOiI4NTFhZWFiOS1mYTU2LTQzMWYtYmNlMC0zZWYzZGY5ZGY3ZjciLCJ1c2VybmFtZSI6InRvckBhdmlsb24uY28ifQ.LK6yvTxrfcvnhA9vit42L5Plren2lAyR6ChQfN_3G1vLnJAALuHbnX_VkG_UlF854MVgFQQen1jLSqgRYZXA4s85i1XQqk9BmrPoEzOz1yLVwlA0UAxn0J_tNUugZ70zNwGy7rDPE_8K_V0j8Os55rNJVkRCGpBDzxxCLONJ5bPWZrJqo689EwtcPnTwbsAnkfdUatyCWJj83Z9DCeJiqFIc0HXKT1HK92BoLGoRr41U6wSO2LK62u7Q0Z-cwqXm-0MERSZ1JUVfkjYuxdD2qjzM0O35QJHbUTlcywUOkbpEofnyoNdmp1xwAWqU44X36FfB4ubch2lwHs3FwSkBsA",
};

const state = () => ({
  rooms: {
    all: [],
  },
  room: null,
  agents: [],
  channel: "main",
  selectedRoomID: null,
  user: null,
  modal: true,
});

const getters = {
  rooms(state) {
    return state.rooms;
  },
  room(state) {
    return state.room;
  },
  agents(state) {
    return state.agents;
  },
  channel(state) {
    return state.channel;
  },
  selectedRoomID(state) {
    return state.selectedRoomID;
  },
  user(state) {
    return state.user;
  },
  modal(state) {
    return state.modal;
  },
};

const mutations = {
  setAllRooms(state, value) {
    state.rooms["all"] = value;
  },
  setRoom(state, value) {
    state.room = value;
  },
  setAgents(state, value) {
    state.agents = value;
  },
  setChannel(state, value) {
    state.channel = value;
  },
  setSelectedRoomID(state, value) {
    state.selectedRoomID = value;
  },
  setUser(state, value) {
    state.user = value;
  },
  setModal(state, value) {
    state.modal = value;
  },
};

const actions = {
  async getUserInfo(context) {
    const mockupResponse = {
      // id: "bbb48d1c-823b-49a6-b906-448648492e1b",
      id: "002",
      lastUpdated: 1655456678.012765,
      creation: 1652437973.729481,
      email: "fuse@avilonrobotics.com",
      email_verified: true,
      name: "Fuse Poramet",
      phone_number: "+66622074001",
      phone_number_verified: false,
      firstName: "Fuse",
      lastName: "Poramet",
      address: [
        {
          address:
            "1313/1 LP \u0e1e\u0e2b\u0e25\u0e42\u0e22\u0e18\u0e34\u0e1944",
          district: "Khet Chatuchak",
          email: "fuse@avilonrobotics.com",
          firstname: "Fuse",
          lastname: "Poramet",
          name: "Home",
          id: 0,
          phone: "+66622074001",
          postcode: "10900",
          subDistrict: "Khwaeng Sena Nikhom",
          province: "Bangkok",
          location: {
            lat: 13.847383380603265,
            lng: 100.57081632553499,
          },
        },
      ],
      defaultShippingAddress: 0,
      sub: "bbb48d1c-823b-49a6-b906-448648492e1b",
      version: 1,
      consent: {
        privacy_policy: {
          isAccepted: true,
        },
      },
      shippingType: "DELIVERY",
      paymentMethod: "Other",
    };

    await setTimeout(() => {
      context.commit("setUser", mockupResponse);
    }, 1000);
  },
  async fetchRooms(context, token, data = []) {
    // const customerID = context.state.user?.id;
    const customerID = "dc2cbe2a-1439-4514-854e-2f2edd1834ba";
    if (!customerID) {
      throw { code: "NO_USER_INFOMATION", messages: "user not authorized" };
    }
    do {
      const response = await axios.get(
        API_ENDPOINT + `/rooms/customer/${customerID}`,
        {
          headers: headers,
          params: {
            nextToken: token,
          },
        }
      );

      const { Items } = response.data;
      token = response.data.NextToken;
      if (Items) {
        data = [...data, ...Items];
      }
    } while (token !== null);
    console.log("Customer get rooms", data);
    context.commit("setAllRooms", data);
  },

  async getRoom(context) {
    if (!context.state.user?.id) {
      throw { code: "NO_USER_INFOMATION", messages: "user not authorized" };
    }
    const response = await axios.get(
      API_ENDPOINT + "/room/" + context.state.user.id,
      {
        headers: headers,
      }
    );
    console.log("getRoom", response);
    if (response.status !== 200) {
      console.error("getRoom error");
    }
    const { data } = response;

    if (!data.RoomID) {
      throw {
        code: "ROOM_ID_NOT_FOUND",
        messages: "room ID not found!",
      };
    }

    const clone = data;
    context.commit("setRoom", clone);
  },

  async listAgent(context) {
    const API_ENDPOINT =
      "https://qh0mnf23g6.execute-api.ap-southeast-1.amazonaws.com/dev";
    const response = await axios.get(API_ENDPOINT + "/agents", {
      headers: headers,
    });

    console.log("listAgent", response);
    if (response.status !== 200) {
      console.error("listAgent error");
    }

    const { data } = response;
    // console.log(context, data);
    context.commit("setAgents", data);
  },

  async listMessages(context, payload) {
    const response = await axios.get(
      API_ENDPOINT + "/" + payload + "/messages",
      {
        headers: headers,
      }
    );

    console.log(response);
    if (response.status !== 200) {
      console.error("listMessage error");
    }

    const { data } = response;
    console.log(data);
  },

  async createRoom(context) {
    const body = {
      roomID: context.state.user.id,
      CreatorID: context.state.user.id,
      CreatorName:
        context.state.user.firstName + " " + context.state.user.lastName,
    };

    const response = await axios.post(API_ENDPOINT + "/room", body, {
      headers: headers,
    });

    console.log(response);
  },

  async closeRoom(context, roomID) {
    const response = await axios.get(
      API_ENDPOINT + "/room/" + roomID + "/close",
      {
        headers: headers,
      }
    );

    console.log(response);
  },

  async assignAgent(context, roomID, agentID, agentName, channel) {
    const body = {
      AgentID: agentID,
      AgentName: agentName,
      Channel: channel,
    };

    const response = await axios.post(
      API_ENDPOINT + "/room/" + roomID + "/assign-agent",
      body,
      {
        headers: headers,
      }
    );

    console.log(response);
  },

  async unassignAgent(context, roomID, agentID, agentName, channel) {
    const body = {
      AgentID: agentID,
      AgentName: agentName,
      Channel: channel,
    };

    const response = await axios.post(
      API_ENDPOINT + "/room/" + roomID + "/unassign-agent",
      body,
      {
        headers: headers,
      }
    );

    console.log(response);
  },

  async assignCustomer(context, roomID, customerID, customerName) {
    const body = {
      CustomerID: customerID,
      CustomerName: customerName,
    };

    const response = await axios.post(
      API_ENDPOINT + "/room/" + roomID + "/assign-customer",
      body,
      {
        headers: headers,
      }
    );

    console.log(response);
  },

  async addMessage(context, payload) {
    const body = {
      SenderID: payload.senderID,
      Channel: payload.channel,
      Text: payload.text,
    };

    const response = await axios.post(
      API_ENDPOINT + "/room/" + payload.roomID + "/add-message",
      body,
      {
        headers: headers,
      }
    );

    console.log(response);
  },

  async getMessageByRoomID(context, payload) {
    let nextToken = null;
    let messages = [];
    if (payload.nextToken) {
      nextToken = payload.nextToken;
    }
    do {
      const response = await axios.get(
        API_ENDPOINT + `/room/${payload.roomID}/${payload.channel}/messages`,
        {
          params: {
            nextToken: nextToken,
          },
        }
      );
      if (response.status !== 200) {
        console.error("getMessageByRoomID error");
      }

      nextToken = response.data.NextToken;
      // console.log(nextToken)
      const { Items } = response.data;
      if (Items) {
        messages = [...messages, ...Items];
      }
      if (payload.lastest) {
        break;
      }
      if (messages.length > 20) break;
    } while (nextToken);
    return { messages: messages, nextToken: nextToken };
  },

  async getNotesByRoomID(context, payload) {
    let nextToken = null;
    let notes = [];
    do {
      const response = await axios.get(
        API_ENDPOINT + `/room/${payload.roomID}/notes`,
        {
          params: {
            nextToken: nextToken,
          },
        }
      );
      if (response.status !== 200) {
        console.error("getMessageByRoomID error");
      }

      nextToken = response.data.NextToken;
      // console.log(nextToken)
      const { Items } = response.data;
      if (Items) {
        notes = [...notes, ...Items];
      }
    } while (nextToken);
    return { notes: notes, nextToken: nextToken };
  },

  async addNoteToRoomID(context, payload) {
    const body = {
      CreatorID: payload.creatorID,
      CreatorName: payload.creatorName,
      Text: payload.text,
    };

    console.log("addNoteToRoomID", payload, body);

    const response = await axios.post(
      API_ENDPOINT + "/room/" + payload.roomID + "/add-note",
      body,
      {
        headers: headers,
      }
    );

    console.log(response);
    if (response.status !== 200) {
      console.error("addNote error");
    }
  },

  async getRoomsByAgentID(context, payload) {
    // const response = await axios.get(API_ENDPOINT + `rooms/agent/${payload}`);
    let nextToken = null;
    let rooms = [];
    if (payload.nextToken) {
      nextToken = payload.nextToken;
    }
    do {
      const response = await axios.get(
        API_ENDPOINT + `rooms/agent/${payload.agentID}`,
        {
          params: {
            nextToken: nextToken,
          },
        }
      );
      if (response.status !== 200) {
        console.error("getRoomsByAgentID error");
      }

      nextToken = response.data.NextToken;
      // console.log(nextToken)
      const { Items } = response.data;
      if (Items) {
        rooms = [...rooms, ...Items];
      }
      if (payload.lastest) {
        break;
      }
      // if (rooms.length > 20) break;
    } while (nextToken);
    // return { rooms: rooms, nextToken: nextToken };
    console.log("agent rooms", rooms);
  },

  async typing(context) {
    const creatorID = "002";
    const creatorName = "tester2";
    const body = {
      CreatorID: creatorID,
      CreatorName: creatorName,
    };
    const response = await axios.post(
      API_ENDPOINT + `/room/${context.state.selectedRoomID}/typing`,
      body,
      {
        headers: headers,
      }
    );
    console.log(response.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
