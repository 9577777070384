import Vue from "vue";
import VueRouter from "vue-router";
import { authenticationGuard } from "./guards";
import multiguard from "vue-router-multiguard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome",
    components: {
      default: () => import("@/views/WelcomeScreen.vue"),
    },
  },
  {
    path: "/auth",
    name: "Auth",
    components: {
      default: () => import("@/views/AuthScreen.vue"),
    },
    meta: {
      title: "Sign In | Villa Chat System",
    },
  },
  {
    path: "/wait-approve",
    name: "WaitApprove",
    component: () => import("@/views/WaitApprove.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    components: {
      default: () => import("@/views/chat/Profile.vue"),
    },
    beforeEnter: multiguard([authenticationGuard]),
  },
  {
    path: "/chat",
    name: "Chat",
    components: {
      default: () => import("@/views/chat/ChatView.vue"),
    },
    beforeEnter: multiguard([authenticationGuard]),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    components: {
      default: () => import("@/views/chat/DashboardView.vue"),
    },
    beforeEnter: multiguard([authenticationGuard]),
  },
  {
    path: "/search",
    name: "Search",
    components: {
      default: () => import("@/views/chat/search/SearchView.vue"),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = "Villa Chat Systems";
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
