/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    // "aws_cognito_identity_pool_id": "ap-southeast-1:9a9f6dd9-3e1f-4934-8788-f1bf44a49aa8", //dev
    "aws_cognito_identity_pool_id": "ap-southeast-1:1e1b4274-dd3e-47dc-9a13-7102ad743dc5", //master
    "aws_cognito_region": "ap-southeast-1",
    // "aws_user_pools_id": "ap-southeast-1_pTIZW2oI3", //dev
    "aws_user_pools_id": "ap-southeast-1_Y2zYapgmt", //master
    // "aws_user_pools_web_client_id": "3m5gb5ucfe0dfpc76jpuldk3g3", //dev
    "aws_user_pools_web_client_id": "6as1n2m33e9mt5kblgg01j6o92", //master
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
"aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    // "aws_appsync_graphqlEndpoint": "https://7olc3hemljf67gifi7exwjpuka.appsync-api.ap-southeast-1.amazonaws.com/graphql", //dev
    "aws_appsync_graphqlEndpoint": "https://47rsa2os5jauvfjhf4a5zbkzay.appsync-api.ap-southeast-1.amazonaws.com/graphql", //master
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    // "aws_appsync_apiKey": "da2-fqj6kihornhftk62yi67n2pqn4", //dev
    "aws_appsync_apiKey": "da2-ai5c6gi2hffj3hp2w3xhndl4he", //master
    "aws_cloud_logic_custom": [
        {
            "name": "saletoolapi",
            // "endpoint": "https://vp797zxshe.execute-api.ap-southeast-1.amazonaws.com/dev", //dev
            "endpoint": "https://cdjien6idi.execute-api.ap-southeast-1.amazonaws.com/master", //master
            "region": "ap-southeast-1"
        }
    ],
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "PHONE_NUMBER"
    ]
};


export default awsmobile;
