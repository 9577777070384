<template>
  <div ref="app" id="app">
    <KeepAlive :include="['ChatView']">
      <router-view />
    </KeepAlive>
    <NotificationCenter class="tw-fixed tw-bottom-2 tw-right-2" />
    <!-- <RoomAlert class="tw-fixed tw-top-4 tw-left-1/3 tw-z-[50]" /> -->
  </div>
</template>

<script>
export default {
  components: {
    NotificationCenter: () => import("@/components/NotificationCenter.vue"),
    // RoomAlert: () => import("@/components/RoomAlert.vue"),
  },
  data() {
    return {
      // notificationMock: {
      //   Type: "agent-join-request-notification",
      //   RoomID: "670920ee-3e84-405a-beaf-506a08762bf6",
      //   RefID: "WshdKPE3vjaC9jcxfdSqLf",
      //   NewAgent: {
      //     ID: "Tor",
      //     Name: "Tor",
      //   },
      //   CurrentAgent: {
      //     ID: "poramet122026",
      //     Name: "",
      //   },
      //   Channel: "Main",
      //   CreatedAt: "2022-08-18T08:51:40.843241381Z",
      // },
    };
  },
  computed: {},
  async mounted() {
    await this.requestNotificationPermission();
  },
  methods: {
    async requestNotificationPermission() {
      if (!("Notification" in window)) {
        // Check if the browser supports notifications
        window.alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        // Skip
      } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            console.log("granted notification.");
          }
        });
      }

      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them anymore.
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

html,
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#app {
  font-family: "Roboto", sans-serif;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
