import Vue from "vue";
import Vuex from "vuex";

import chat from "./modules/chat";
import customerChat from "./modules/customerChat";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    chat: chat,
    customerChat: customerChat,
  },
});
